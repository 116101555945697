import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoadStudentsApi extends Vue {
  protected readonly LIMIT = 200

  protected getSearchParamsBase(branchId: number) {
    const params = {
      branchId: branchId,
      offset: 0,
      limit: this.LIMIT,
    }

    return params
  }

  protected async loadStudents(params: any) {
    const res = await Vue.prototype.$http.httpWithToken.get(`/v3/students`, {
      params: params,
    })

    return res.data
  }
}
